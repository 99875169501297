import React from 'react'
import { graphql } from 'gatsby';
import Heading from 'ui/elements/Heading';
import Contact from 'components/shared/Contact';
import { Hidden } from 'react-grid-system';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import withLocalization from 'decorators/withLocalization';
import { Section } from 'components/Layout/styles';
import MeetingLayout from 'components/Layout/MeetingLayout';
import { flattenResponse } from 'utils/helpers';
import { getImageURL } from 'utils/sanity';
import ContentBox from 'widgets/ContentBox';

import { palette } from 'modules/OpenPrograms/utils';
import { Summary } from 'modules/OpenPrograms';

const ProgramsIndex = ({
  data,
  location,
}) => {
  const imageURL = getImageURL(data, 'sanityProgramsIndex.hero.image.asset._id');
  const { i18n } = useTranslation();
  const plainPrograms = flattenResponse(data, 'allSanityProgram');
  const plainCategories = flattenResponse(data, 'allSanityCategories');
  const contact = data.contact ?? undefined;

  const layoutProps = {
    palette,
    icon: 'open-programs',
    title: 'Open programs',
    data: data.sanityProgramsIndex,
  };

  return (<>
    <MeetingLayout {...layoutProps}>
      <GatsbySeo
        title='Open programs'
        openGraph={{
          title: 'Open programs',
          url: location.href,
          locale: i18n.language,
          images: imageURL ? [
            {
              width: 800,
              height: 600,
              url: imageURL,
              alt: 'Open programs',
            }
          ] : undefined
        }}
      />
      <Section>
        <Hidden xs sm>
          <Heading color={palette.variant} level={1}>
            Open Programs
          </Heading>
        </Hidden>

        <ContentBox blocks={data.sanityProgramsIndex._rawTextAboveList} />
      </Section>

      <Section fillContainer>
        <Summary programs={plainPrograms} categories={plainCategories} />
      </Section>

      <Section fillContainer>
        <ContentBox blocks={data.sanityProgramsIndex._rawTextBelowList} />
      </Section>
    </MeetingLayout>
    {contact && <Contact contact={contact} />}
  </>)
};

export const query = graphql`
  query ProgramsIndex($id: String, $language: String!) {
    sanityProgramsIndex(id: {eq: $id}) {
      title {
        localized
      }
      hero {
        ...HeroFragment
      }
      _rawTextAboveList(resolveReferences: { maxDepth: 10 })
      _rawTextBelowList(resolveReferences: { maxDepth: 10 })
    }
    contact: sanityContact {
      ...ContactFragment
    }
    allSanityProgram {
      edges {
        node {
          id
          title
          language
          ects
          subtitle {
            localized
          }
          _rawIntro(resolveReferences: { maxDepth: 10 })
          topics
          category {
            id
          }
          fallback_fee
          fallback_location
          programRuns {
            active
            earlyBirdDate
            feeEarlyBird
            feeRegular
            modules {
              active
              endDate
              location
              startDate
              title
            }
          }
        }
      }
    }

    allSanityCategories {
      edges{
        node {
          title
          id
        }
      }
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withLocalization(ProgramsIndex);


